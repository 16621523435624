import React from 'react';
import './Error.css';

const Error = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'rgb(20, 20, 20)',
      }}>
      <section id='Background' className='Background'>
        <div onclick='ActiveSquare(this)' className='Square'></div>
      </section>
      <div className='glass-Box'>
        <h1>4 ▢ 4</h1>
        <p>Oops! page not found</p>
      </div>
    </div>
  );
};

export default Error;
