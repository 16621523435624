import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Error from './Error';
import UserId from './user-id';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary'
import Home from './Home';

const App = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary
        FallbackComponent={<Error />}
        onReset={() => {
          // reset the state of your app here
        }}
        resetKeys={['someKey']}>
        <Router>
          <Routes>
            <Route exact path={`/`} element={<Home />} />
            <Route exact path={`/:userId`} element={<UserId />} />
            <Route path={`/error` || '*'} element={<Error />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
library.add(fab, fas);
